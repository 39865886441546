import { useTranslation } from 'react-i18next';
import ResponsivePicture from 'shared/components/responsive-picture';
import { Level } from 'shared/types';
import { Title } from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";
import styles from "./activity-section.module.css";

interface Props {
  isLast: boolean,
  activity: {
    level: Level
  }
}

/**
 * renders the level-up activity in the activity section on the gamification-page
 *
 * @category gamification
 * @subcategory components
 * @exports ActivityLevelSection
 * @component
 */
export default function ActivityLevelSection({ isLast, activity }: Props) {
  const { t } = useTranslation('translations');

  const { level } = activity;
  const {
    name,
    image,
  } = level;

  const title = t('New level reached');

  const description = t('You are a real cyber security expert!');

  return (
        <div className={`activity d-flex flex-column py-3 border-top ${isLast ? 'border-bottom mb-3' : ''}`}>
            <div className='mb-3 d-flex flex-row'>
                <Title order={3} size="h5">{title}</Title>
            </div>

            <div  className={`${styles.activityBadgeContent} d-flex d-row position-relative`}>

                <div className='activity-badge'>
                    <ResponsivePicture
                        className='activity-badge-image'
                        src={image}
                        alt=""
                        fit='contain'
                        defaultHeight={100}
                    />
                </div>
                <span className='mx-2' />

                <div className='d-flex flex-column w-100'>
                    <Title className='mb-3' order={4} size="h5">{name}</Title>
                    <span>
                        {description}
                    </span>
                </div>
            </div>
        </div>
  );
}
