import React, { useState, useEffect, useRef } from "react";
import {
  IconCircleArrowLeftFilled,
  IconCircleArrowRightFilled,
} from "@sosafe-platform-engineering/fe-lib-ui-mantine-react/icons";
import { Carousel } from "@sosafe-platform-engineering/fe-lib-ui-mantine-react/carousel";
import { useTranslation } from "react-i18next";
import '@mantine/carousel/styles.css';
import style from "./carousel.module.css";

type CarouselComponentType = { components: JSX.Element[]; slides: number };

export const CarouselComponent = (props: CarouselComponentType) => {
  const { t } = useTranslation("flamingo");
  const [currentSlide, setCurrentSlide] = useState(0);
  const slideRefs = useRef<(HTMLDivElement | null)[]>([]);

  // Calculate visible slides based on the current index
  const getVisibleSlides = (currentSlideNum: number) => {
    const visibleIndices: number[] = [];
    for (let i = 0; i < props.slides; i++) {
      const index = (currentSlideNum * props.slides + i) % props.components.length;
      visibleIndices.push(index);
    }
    return visibleIndices;
  };

  // Update visibility of slides based on currentSlide
  useEffect(() => {
    const visibleIndices = getVisibleSlides(currentSlide);
    slideRefs.current.forEach((slide, index) => {
      if (slide) {
        slide.setAttribute("aria-hidden", visibleIndices.includes(index) ? "false" : "true");
      }
    });
  }, [currentSlide]);

  // Function to assign refs to slides
  const assignRef = (el: HTMLDivElement | null, index: number) => {
    slideRefs.current[index] = el; // Assigning ref
  };

  return (
    <div className={style.card} role="region" aria-label={t("available_learning_modules")}>
      <Carousel
        aria-roledescription={t("ariaRoleDescription_learningModules")}
        aria-atomic="false"
        slidesToScroll={props.slides}
        align="start"
        slideSize={`${100 / props.slides}%`}
        className={style.container}
        loop
        onSlideChange={(index) => {
          setCurrentSlide(index);
        }}
        nextControlIcon={
          <IconCircleArrowRightFilled className={style.icon} stroke={2} />
        }
        previousControlIcon={
          <IconCircleArrowLeftFilled className={style.icon} stroke={2} />
        }
        previousControlProps={{
          'aria-label': `${t("learningPath_prev_button")}`,
        }}
        nextControlProps={{
          'aria-label': `${t("learningPath_next_button")}`,
        }}
        dragFree
        withIndicators={false}
        classNames={{
          root: style.mantineRoot,
          controls: style.mantineControls,
          control: style.mantineControl,
        }}
      >
        {props.components.map((element, key) => (
          <Carousel.Slide
            className={style.slide}
            key={`slide-${key}`}
            ref={(el) => assignRef(el, key)}
          >
            {element}
          </Carousel.Slide>
        ))}
      </Carousel>
    </div>
  );
};
