import React from "react";
import { Button } from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";
import { useHistory, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import useInitTranslations from "lang/use-init-translations.hook";
import Loading from "shared/components/loading";
import Helmet from "shared/components/helmet";
import { queryKey, useSoSafeConnect } from "shared/modules/sosafe-connect";
import { useQuery } from "@tanstack/react-query";
import { AwarenessAssessmentSurvey } from "./components/awareness-assessment-survey";

import style from "./awareness-assessment.page.module.css";
import translations from "./lang";
import { AwarenessAssessmentRouteStateType } from "./types";

export const AwarenesssAssessmentPage = () => {
  const history = useHistory();
  const location = useLocation<AwarenessAssessmentRouteStateType>();
  const awarenessAssessmentId = location.state?.id ?? null;
  const { translationsReady } = useInitTranslations(
    translations,
    "awareness-assessment"
  );

  const { endPoints } = useSoSafeConnect();
  const { t } = useTranslation(["awareness-assessment", "helmetTranslations"]);

  const { data, isLoading: isLoadingSurvey } = useQuery(
    [queryKey.SURVEY_HUB, awarenessAssessmentId],
    () => endPoints.surveyHubApi.fetch.getSurveyDataById(awarenessAssessmentId),
    {
      enabled: !!awarenessAssessmentId,
    });

  const onExit = () => {
    history.push("/");
  };

  if (!location.state?.module || !awarenessAssessmentId) {
    history.push("/");
  }

  const isLoading = !translationsReady || isLoadingSurvey || !data.surveyData;

  return (
    <div className={style.container}>
      <Helmet title={t("helmetTranslations:awarenessAssessment")} />
      {isLoading ? (
        <div className={style.loadingContainer}>
          <Loading />
        </div>        
      ) : (
        <div className={style.page}>
          <Button
            variant="outline"
            className={style.exitButton}
            aria-label={t("awareness-assessment:exitAssessment")}
            onClick={onExit}
          >
            {t("awareness-assessment:exitAssessment")}
          </Button>
          <AwarenessAssessmentSurvey
            surveyId={awarenessAssessmentId}
            surveyData={data.surveyData}
            module={location.state.module}
          />
        </div>
      )}
    </div>
  );
};
