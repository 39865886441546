import React, { useState } from "react";
import ResponsivePicture from "shared/components/responsive-picture";
import { BadgeProps } from "shared/modules/sosafe-connect/response-type";
import { BadgeModal } from "flamingo-e-learning-platform/components/badge-modal/badge-modal";
import { useTranslation } from "react-i18next";
import { useModalContext } from "modal-context/modal-context";

import PersistentTooltip from "shared/components/persistent-tooltip/persistent-tooltip";
import style from "./badge-button.module.css";

interface BadgeComponentProps {
  badge: BadgeProps;
  fromView: string;
  height: number;
}

/**
 * Component to display an achievement badge
 *
 * @category elearning
 * @subcategory components
 * @exports BadgeButton
 * @component
 */
const BadgeButton = ({ badge, height = 75 }: BadgeComponentProps) => {
  const { received } = badge;
  const [openBadge, setOpenBadge] = useState<BadgeProps | null>(null);
  const { t } = useTranslation("flamingo");
  const modal = useModalContext();

  const handleBadgeClick = (selectedBadge: BadgeProps) => {
    setOpenBadge(selectedBadge);
    modal.fire({
      html: (
        <BadgeModal onClose={() => setOpenBadge(null)} badge={selectedBadge} />
      ),
      onClose: async () => {
        modal.close();
      },
    });
  };

  return (
    <PersistentTooltip
      label={badge.received ? badge.name : t("badgeUnachievedTip", { badgeName: badge?.name })}
      // events={{ hover: true, focus: true, touch: false }}
    >
      <button onClick={() => handleBadgeClick(badge)} className={style.button}>
        <ResponsivePicture
          grayscale={!received}
          src={badge.image}
          alt={`${badge?.name} - ${
            badge?.received ? t("badgeAchieved") : t("badgeNotAchieved")
          }`}
          fit="contain"
          defaultHeight={height}
        />
      </button>
    </PersistentTooltip>
  );
};

export default BadgeButton;
