import React, { useEffect } from "react";
import { ProductAnalytics } from "shared/modules/product-analytics";
import { ANALYTICS_TYPE } from "shared/modules/sosafe-connect";
import {
  ACHIEVEMENT_PAGE_OPENED_FROM,
  TRACKED_EVENTS,
} from "elearning/helpers";
import { useHistory } from "react-router";
import Helmet from "shared/components/helmet";
import { useTranslation } from "react-i18next";
import { Container, Grid, Group, Stack } from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";
import styles from "./achievements.page.module.css";
import LevelCard from "./components/level-card/level-card";
import ExperienceCard from "./components/experience/experience";
import ActivitiesCard from "./components/activities/activities";
import { BadgesCard } from "./components/badges/badgets";

/**
 *
 * @category flamingo
 * @exports AchievementsPage
 * @component
 */

export default function AchievementsPage() {
  const history = useHistory<{ openedFrom: string }>();
  const { t } = useTranslation("helmetTranslations");

  useEffect(() => {
    ProductAnalytics.getInstance().trackEvent(
      TRACKED_EVENTS.ACHIEVEMENT_PAGE_OPENED,
      {
        source:
          history.location.state?.openedFrom ||
          ACHIEVEMENT_PAGE_OPENED_FROM.DIRECT_ACCESS,
      }
    );
  }, [history.location.state?.openedFrom]);

  return (
    <>
      <Helmet title={t("Achievements")} />
      <Container maw={1304} className={styles.container}>
        <Group w="100%" className={styles.group}>
          <Grid gutter="lg" className={styles.grid}>
            <Grid.Col span={{ base: 12, xs: 4 }}>
              <Stack gap="lg">
                <LevelCard />
                <ExperienceCard />
              </Stack>
            </Grid.Col>
            <Grid.Col span={{ base: 12, xs: 8, md: 4,}}>
              <ActivitiesCard />
            </Grid.Col>
            <Grid.Col span={{ base: 12, xs: 8, md: 4,}} offset={{ base: 0, xs: 4, md: 0}}>
              <BadgesCard />
            </Grid.Col>
          </Grid>
        </Group>
      </Container>
    </>
  );
}
